import React from "react"
import { Link } from "gatsby"

const ProfessionalService = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img professional"
          data-bg="assets/images/banners/professional.jpg"
        >
          <div className="container">
            <div className="row"></div>
          </div>
        </div>

        {/* <!--======== careers-experts Start ==========--> */}
        <div className="careers-experts-wrapper section-space--pt_100">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 m-auto">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_30">
                  <h3 className="heading">
                    Become a Part Of Our Big Family To Inspire And Get Inspired
                    By{" "}
                    <span className="text-color-primary">
                      {" "}
                      Professional Experts.
                    </span>
                  </h3>
                </div>
                {/* <!-- section-title-wrap Start --> */}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="ht-simple-job-listing move-up animate">
                  <div clas="list">
                    <p className="pb-5 text-center">
                      No Current Jobs are available right now.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--======== careers-experts End ==========--> */}

        <div
          className="container section-space--ptb_100"
          style={{ display: "none" }}
        >
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-6">
              <div className="contact-form-wrap">
                <h4 className="apply-now">Professional Service Apply Now</h4>
                <h5 id="professional-service" style={{ paddingTop: "40px" }}>
                  To apply for the Professional Service, send your Resume at{" "}
                  <a href="mailto:careeers@aamanto.com">careeers@aamanto.com</a>{" "}
                  with job ID Reference.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProfessionalService
