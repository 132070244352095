import * as React from "react"
import Layout from "../components/layout"
import ProfessionalService from "../components/Careers/ProfessionalService"






const professional = () => {
  return (
  <Layout>
   <ProfessionalService/>
  </Layout>
)
};
export default professional;
